<template>
  <div class="ele-body">
      <el-form ref="form" label-width="120px" class="d-flexspabet">
        <el-card shadow="never" class="w-40 mt-20" style="height:160px">
          <div class="d-flex smsInner">
            <div class="blueLine">|</div>
            <div>当前余额（元）</div>
          </div>
          <div class="d-flexspabet" style="padding:20px 0 10px 30px">
            <div style="font-size:25px">{{amountData.balance}}</div>
            <div>
              <el-button type="primary" @click="handlePurchase"
                         v-if="permission.includes('sys:marketing_check_ads:buy')">立即充值
              </el-button>
            </div>
          </div>
        </el-card>
        <el-card shadow="never" class="w-40 mt-20" style="height:160px">
          <div class="d-flex smsInner">
            <div class="blueLine">|</div>
            <div>剩余时长（分钟）</div>
          </div>
          <div class="d-flexspabet" style="padding:20px 0 10px 30px">
            <div style="font-size:25px">{{ amountData.time_balance }}</div>
          </div>
        </el-card>
      </el-form>

      <el-card shadow="never" class="mt-20 " style="width:100%">
        <div><span class="blueLine">|</span>充值记录</div>
        <!-- 数据表格 -->
        <ele-data-table ref="table" :config="table" :choose.sync="choose" height="calc(100vh - 315px)" highlight-current-rows :stripe=true>
          <template slot-scope="{index}">
            <el-table-column type="index" :index="index" label="编号" width="60" align="center"  show-overflow-tooltip/>
            <el-table-column prop="set_area" label="地区"  show-overflow-tooltip min-width="120px"/>
            <el-table-column prop="role_name" label="管理员"  show-overflow-tooltip min-width="120px"/>
            <el-table-column prop="real_name" label="真实姓名"  show-overflow-tooltip  min-width="120px"/>
            <el-table-column prop="mobile" label="手机号"  show-overflow-tooltip min-width="120px"/>
            <el-table-column prop="price"  label="充值金额（元 ）"  show-overflow-tooltip  min-width="120px"/>
            <el-table-column prop="create_time"  label="充值时间"  show-overflow-tooltip  min-width="120px"/>
            <el-table-column prop="type_name" label="充值方式"  show-overflow-tooltip min-width="120px"/>
            <el-table-column prop="package_title" label="充值内容"  show-overflow-tooltip min-width="120px"/>
          </template>
        </ele-data-table>

      </el-card>

      <!--购买短信套餐-->
      <el-dialog v-dialogDrag title="请选择充值套餐" :visible.sync="dialogVisiblePlans" custom-class="ele-dialog-form"
                 :lock-scroll="false" class="plans" :close-on-click-modal="false" :close-on-press-escape="false"
                 :show-close="false" :destroy-on-close="true">
        <el-card shadow="never">
          <div class="plansBox" style="text-align: center; ">
            <el-form :model="editForm" ref="editForm" label-width="100px">
              <div style="margin-top: 10px">
                <el-form-item label="地区：">
                  <el-select v-model="pid" @change="handleChangeProv(pid)"
                             placeholder="请选择省" class="selectStyle mr-10 mb-20" clearable style="width: 140px">
                    <el-option v-for="option in provArr" :value="option.pid" :key="option.pid"
                               :label="option.name"></el-option>
                  </el-select>
                  <el-select v-model="cid" @change="handleChangeCity(cid)"
                             placeholder="请选择市" class="selectStyle mr-10 mb-20" clearable style="width: 140px">
                    <el-option v-for="option in cityArr" :value="option.cid" :key="option.cid"
                               :label="option.name"></el-option>
                  </el-select>
                  <el-select v-model="aid" @change="$forceUpdate(),handleDis(aid)"
                             placeholder="请选择区县" class="selectStyle mr-10 mb-20" clearable style="width: 140px">
                    <el-option v-for="option in districtArr " :value="option.aid" :key="option.aid"
                               :label="option.name"></el-option>
                  </el-select>
                </el-form-item>
              </div>
            </el-form>
            <div class="plansItem el-cen-flx" >
                
              <div class="plansInner " v-for="option in editFive"
                   :class="{'greenborder':option.greenborder}"
                   @click="handleChange(option.id,option.year_price)" :key="option.id">
                <div class="plansTitle">{{ option.title }}</div>
<!--                <div class="plansMoney">￥{{ option.price }}</div>-->
                <div class="plansCluse">单价：{{ option.price }}元/分钟（包含录音）</div>
                <div class="plansNum">月租：{{ option.month_price }}元/月</div>
              </div>

            </div>
          </div>

        </el-card>
        <div slot="footer">
          <el-button type="primary" @click="purchaseSave(4)">账户余额购买</el-button>
          <el-button type="primary" @click="purchaseSave(1)">立即充值购买</el-button>
          <el-button @click="dialogVisiblePlans=false">取消</el-button>
        </div>
      </el-dialog>
  </div>
</template>

<script>
import {
  mapGetters
} from 'vuex'
import setting from '../../../config/setting'

export default {
  name: 'SysUser',
  data () {
    return {
      table: {
        url: '/VmRechargeOrder/index',
        where: {}
      }, // 表格配置

      activeName: 'first',
      provArr: [],
      cityArr: [],
      districtArr: [],
      editFive: [],
      editSix: [],
      allPhone: [],
      disabled: false,
      dialogVisiblePlans: false,
      dialogVisibleSending: false,
      dialogVisibleTemplates: false,
      dialogVisiblePhone: false,
      choose: [], // 表格选中数据
      editFirst: {},
      editForm: {
        sendContent: '',
        sendNum: '0',
        sendPrice: '1',
        pid: '',
        cid: '',
        aid: '',
        type: 2
      },
      checkForm: {},
      imageUrl: false,
      headImg: '',
      old_price: '',
      voiceRecharge: {},
      id: '',
      price: '',
      handleInputClick1Index: 0,
      amountData: {},
      pid: '',
      cid: '',
      aid: '',
    }
  },
  created () {


    const loading = this.$loading({lock: true});
    this.$http.get('/VmAreas/balance').then(res=>{
      this.amountData=res.data.data
      loading.close()
    })
    this.$http.get('/common/vm_province_list').then(res => {
      let data = JSON.parse(res.data)
      this.provArr = data
    })

  },

  computed: {
    ...mapGetters(['permission']),
  },
  mounted () {},
  methods: {
    handleDistrictArr () {
      let editForm = {
        type: this.editForm.type,
        aid: this.editForm.aid
      }
      // this.$http.post('/Marketing/sms_index', editForm).then(res => {
      //   this.editFirst = res.data
      // })
    },
    hangleTypt () {
      let editForm = {
        type: this.editForm.type,
        aid: this.editForm.aid
      }
      this.$http.post('/Marketing/sms_index', editForm).then(res => {
        this.editFirst = res.data

      })
    },
    form () {},
    exportExcel () {},
    handleChange (id, price) {
      let that = this
      this.id = id
      this.price = price
      //console.log(this.id, this.price)
      this.editFive.forEach(function (item) {
        if (item.id == id) {
          item.greenborder = true
          that.old_price = item.price
        } else {
          item.greenborder = false
        }
      })

    },
    // 购买套餐
    purchaseSave (type) {
      const loading = this.$loading({lock: true, target: document.querySelector('.plans')});
      const that = this;
      this.$http.post('/VmRechargeOrder/add', {
        package_id: this.id,
        type: type,
        pid: this.pid,
        cid: this.cid,
        aid: this.aid,
      }).then(res => {
        loading.close()
        if (type == 4) {
          if (res.data.code === 0) {
            this.dialogVisiblePlans = false
            this.$message({
              type: 'success',
              message: '购买成功'
            })
            this.$refs.table.reload()
            loading.close()
          } else {
            this.$message.error({
              type: 'error',
              message: res.data.msg
            })
          }
        } else {
          if (res.data.code === 0) {
            // 微信支付
            setTimeout(function () {
              window.open(res.data.data.pay_url);
            }, 1000);

            // window.open(res.data.data.pay_url);
            setTimeout(function () {
              that.$confirm('是否支付成功?', '提示', {
                type: 'warning',
                distinguishCancelAndClose: true,
                confirmButtonText: '是',
                cancelButtonText: '否',
              }).then(() => {
                that.$http.post('/VmRechargeOrder/payresult', {
                  order_id: res.data.data.order_id,
                }).then(res_ => {
                  if (res_.data.code === 0) {
                    that.$message({
                      type: 'success',
                      message: '支付成功'
                    })
                  } else {
                    that.$message({
                      type: 'error',
                      message: '支付失败'
                    })
                  }
                  setTimeout(function () {
                    window.location.reload()
                  }, 1000)
                })

              }).catch(e => {
                //console.log('未支付成功')
              })
            }, 2000);


          } else {
            this.$message.error({
              type: 'error',
              message: res.data.msg
            })
          }

        }

      })
    },
    handlePurchase () {
      this.dialogVisiblePlans = true
      let that = this
      const loading = this.$loading({
        lock: true,
        target: document.querySelector('.plans')
      })
      this.$http.get('/VmPackage/index',).then(res => {
        let data = res.data.data
        //console.log(data)
        loading.close()
        data.forEach(function (item) {
          if (item.id == 1) {
            item.greenborder = true
            that.old_price = item.price
          } else {
            item.greenborder = false
          }

        })
        this.editFive = data
        this.id = this.editFive[0].id
        this.price = this.editFive[0].price
      })
    },
    /**
     *选择省
     **/
    handleChangeProv (e) {
      /** 获取被选省份的pid**/
      let pid = ''
      this.provArr.forEach(function (item) {
        if (item.pid == e) {
          pid = item.pid
        }
      })
      /** 根据被选省份的pid获取省市下面的市**/
      this.$http.post('/common/vm_city_list', {
        pid: pid
      }).then(res => {
        let data = JSON.parse(res.data)
        this.cityArr = data
        /** 选择省份清空市县**/
        this.cid = ''
        this.aid = ''
      })
    },
    /**
     *选择市
     **/
    handleChangeCity (e) {
      if (e) {
        /** 获取被选市的cid**/
        let cid = ''
        this.cityArr.forEach(function (item) {
          if (item.cid == e) {
            cid = item.cid
          }
        })
        /** 根据被选市的cid获取市下面的县**/
        this.$http.post('/common/vm_area_list', {
          cid: cid
        }).then(res => {
          let data = JSON.parse(res.data)
          this.districtArr = data
          /** 选择省份清空县**/
          this.aid = ''
        })
      } else {
        this.pid = ''
        this.aid = ''
      }
    },
    handleDis() {
      this.edita = true
    },
    handlecheck () {
      this.dialogVisiblePhone = true
    },

    viewFive (row) {
      this.dialogVisiblePhone = true
      this.allPhone = row.all_phone.split(',')
    },
    saveFive () {
      const loading = this.$loading({
        lock: true
      })
      let editFive = this.editFive
      this.$http.post('/Marketing/set_meal_edit', editFive).then(res => {
        loading.close()
        if (res.data.code == 0) {
          this.$message({
            message: res.data.msg,
            type: 'success'
          })
        } else {
          this.$message.error(res.data.msg)
        }
      }).catch(e => {
        this.$message.error(e.message)

      })
    },

    saveSix () {
      const loading = this.$loading({
        lock: true
      })
      let editSix = this.editSix
      this.$http.post('/Marketing/template_edit', editSix).then(res => {
        loading.close()
        if (res.data.code == 0) {
          this.$message({
            message: res.data.msg,
            type: 'success'
          })
        } else {
          this.$message.error(res.data.msg)
        }
      }).catch(e => {
        this.$message.error(e.message)

      })
    },

    //短信推送提交
    save () {
      // const loading = this.$loading({lock: true});
      let param = {
        content: this.editForm.sendContent,
        pid: this.editForm.pid,
        cid: this.editForm.cid,
        aid: this.editForm.aid,
        prove: this.editForm.sendPrice,
        send_time: this.editForm.send_time,
        tiao: this.editForm.sendNum,
        type: this.editForm.type
      }
      this.$confirm('确定使用余额支付?', '提示', {
        type: 'warning'
      }).then(() => {
        const loading = this.$loading({
          lock: true
        })
        this.$http.post('/marketing/sms_submit_push', param).then(res => {
          loading.close()
          let data = res.data
          if (data.code === 0) {
            this.$message({
              message: data.msg,
              type: 'success'
            })
            this.$refs.table1.reload()
          } else {
            this.$message.error(data.msg)
          }
        }).catch(e => {
          this.$message.error(e.message)

        })

      })

    },
    handleClick (e) {
      if (e.name == 'five') {
        const loading = this.$loading({lock: true})
        this.$http.get('/Marketing/sms_set_meal',).then(res => {
          loading.close()
          this.editFive = JSON.parse(res.data)
        })
      } else if (e.name == 'six') {
        const loading = this.$loading({
          lock: true
        })
        this.$http.get('/Marketing/template_list',).then(res => {
          loading.close()
          let data = JSON.parse(res.data)
          this.editSix = data
        })
      }
    },
    switch_start (row) {
      if (row.type == 1) {
        this.$confirm('确定关闭语音通话吗?', '提示', {type: 'warning'}).then(() => {
          const loading = this.$loading({lock: true})
          this.$http.post('/voice/openingType', {id: row.id, type: 2}).then(res => {
            loading.close()
            let data = JSON.parse(res.data)
            if (data.code === 1) {

              this.$message({
                type: 'success',
                message: '关闭成功'
              })
              this.$refs.table.reload()
            } else {
              this.$message.error(data.msg)
            }
          }).catch(e => {
            loading.close()
            this.$message.error(e.message)
          })
        })
      } else {
        this.$confirm('确定启用语音通话吗?', '提示', {
          type: 'warning'
        }).then(() => {
          const loading = this.$loading({
            lock: true
          })
          this.$http.post('/voice/openingType', {
            id: row.id,
            type: 1
          }).then(res => {
            let data = JSON.parse(res.data)
            loading.close()
            if (data.code === 1) {
              this.$message({
                type: 'success',
                message: '启用成功'
              })
              this.$refs.table.reload()
              this.switchStart = '关闭'
            } else {
              this.$message.error(data.msg)
            }
          }).catch(e => {
            loading.close()
            this.$message.error(e.message)
          })
        })
      }
    }
  }
}
</script>

<style scoped>
.phoneinputWidth {
  width: 166px;
  margin-right: 10px;
  margin-bottom: 20px
}

.el-form-item label:after {
  content: "";
  display: inline-block;
  width: 100%;
}

.el-form-item__label {
  text-align: left;
  height: 50px;
  margin-left: 40px;
}

.el-form-item.is-required .el-form-item__label:before {
  content: none !important;
}

.lifive {
  width: 100%;
  height: 20px;
  background: #2CB394;
  border-top-left-radius: 6% 6%;
  border-bottom-right-radius: 20% 100%;
  margin-left: -10px;
  color: #f9f9f9;
  padding-top: 3px;
  padding-left: 10px;
}

.ele-block .el-upload-dragger {
  width: 100%;
}

.changeStyle {
  width: 150px;
  margin-right: 30px;
}

.ele-body {
  padding: 15px;
}

.el-upload-list el-upload-list--text {
  display: none !important
}

/deep/ .el-tabs__item {
  padding: 0 20px !important;
  font-size: 15px;
}

/deep/ .el-tabs__nav-scroll {
  background: white !important;
  padding-top: 10px;
}

.boxTitle {
  padding: 8px
}

.orderImgBox {
  margin-left: 10px;
  height: 80px;
}

.orderImgBox span {
  font-size: 36px;
}

.driverForm .area /deep/ .el-form-item__content {
  margin-left: 0 !important
}

.user-info-tabs >>> .el-tabs__nav-wrap {
  padding-left: 20px;
}

.w-40 {
  width: 49%;
}

.innerBox {
  width: 100%;
  margin-left: 100px;
  padding-top: 20px;
}

.greenborder {
  border: 1px solid #33cc99 !important
}

.innerItem {
  width: 30%;
  margin-right: 100px;
}

.contentLine {
  line-height: 25px;
  margin-bottom: 40px
}

.smsInner {
  border-bottom: 1px solid rgb(240, 240, 240);
  padding-bottom: 10px;
}

.innerBoxTo {
  width: 20%;
  margin-left: 100px;
  padding-top: 20px;
}

.innerTitle {
  font-size: 16px;
  margin-bottom: 20px;
  margin-left: 13px;
}

.inputWidth {
  width: 100%;
}

.colorOrange {
  color: #ff892e
}

.plansBox {
  width: 100%;
  border: 1px solid rgb(240, 240, 240);
  margin: auto
}

.plansItem {
  /*display: flex;*/
  justify-content: space-around;
  text-align: center;
  /*margin: 0 30px;*/
  width: 100%;
  overflow: hidden;
}

.plansInner {
  padding: 0 10px;
  border: 1px solid rgb(240, 240, 240);
  margin: 20px 0 20px 2%;
  width: 20%;
  float:left;
}
/*.plansInner:nth-child(odd){}*/
.plansInner1 {
  margin: 20px 0;
  width: 18%
}

.plansCon {
  padding: 0 10px 10px;
  border: 1px solid rgb(240, 240, 240);
  width: 100%;
  margin-bottom: 20px;
  height: 310px
}

.plansTitle {
  color: #33cc99;
  padding: 30px 0 20px 0;
}

.plansMoney {
  color: #ff892e;
  padding: 0 20px 20px;
  border-bottom: 1px solid rgb(240, 240, 240);
  width: 40%;
  margin: auto;
}

.plansCluse {
  padding: 20px;
}

.plansNum {
  padding-bottom: 40px;
}

.plansMiaoshu {
  padding: 60px 0 0;
  color: #33cc99
}

.plansDeduct {
  padding-left: 50px;
  padding-bottom: 20px
}

.plansDeduct span {
  color: #ff892e;
  padding-left: 30px
}

.plansContent {
  padding: 30px 0;
  height: 120px
}

/deep/ .el-dialog {
  margin-top: 80px !important;
}

.closeBtn {
  display: inline-block;
  height: 36px;
  width: 90px;
  text-align: center;
  line-height: 36px;
  margin-left: 10px;
  background: red;
  border-radius: 4px;
  font-size: 14px;
  color: white;
  text-decoration: none;
}

.el-textarea__inner {
  padding-top: 0;
}

.el-cen-flx{
    display: flex;
    flex-wrap:wrap;
    justify-content:space-around;
    box-sizing: border-box;
    padding-right: 15px;
}
</style>
